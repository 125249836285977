<template>
  <div style="margin-top: 20px">
    <a-row type="flex" justify="center">
      <a-col :span="20">
        <a-row>
          <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" :xxl="18">
            <div class="tableDiv">
              <el-table @row-click="navigateToServerDetail" class="server_table" v-loading="serverListLoading" element-loading-background="rgba(0, 0, 0, 0)" row-key="uuid" default-expand-all :data="servers" height="100%" style="width: 100%;" :header-cell-style="{color: '#FFFFFF',height: '0'}" :cell-style="{color: '#FFFFFF', padding: '2px'}">
                <template slot="empty">
                  <p class="emptyText" style="color: white">{{ serverListLoading ? '' : '暂无数据' }}</p>
                </template>
                <el-table-column label="地图" width="120" align="center">
                  <template v-slot="scope">
                    <el-image :src="scope['row']['map']?.['url']">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </template>
                </el-table-column>

                <el-table-column label="名称" min-width="100">
                  <template v-slot="scope">
                    <div>
                      <div class="tableText" style="font-weight: 500">
                        {{ scope['row']['name'] }}
                      </div>
                      <div>
                        {{ Bf1Country[scope['row']['country']] }} - <span :style="{color: getServerTypeTextColor(scope['row']['type'])}">{{ Bf1ServerType[scope['row']['type']] }}</span><span v-if="scope['row']['isCustom']" style="color: #FFD700"> - 自订</span> - {{ scope['row']['mode']?.['name'] }}<span v-if="scope['row']['map'] && scope['row']['map']['name']"> - {{ scope['row']['map']?.['name'] }}</span>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="soldier" label="玩家" width="140">
                  <template v-slot="scope">
                    <div class="tableText" style="font-weight: 500">
                      {{ scope['row']['slots']['soldier']['current'] + ' / ' + scope['row']['slots']['soldier']['max'] + (scope['row']['slots']['queue']['current'] > 0 ? ' [' + scope['row']['slots']['queue']['current'] + ']' : '' )  + (scope['row']['slots']['spectator']['current'] > 0 ? ' (' + scope['row']['slots']['spectator']['current'] + ')' : '')}}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </a-col>
          <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" :xxl="{span:4,offset:1}" class="customScrollbar" style="overflow: auto; height: 100%; padding-top: 2px">
            <a-row type="flex" justify="space-between">
              <a-col :xs="24" :sm="11" :md="11" :lg="11" :xl="11" :xxl="24">
                <a-collapse :bordered="false" expand-icon-position="right">

                  <a-collapse-panel>
                    <template slot="header">
                      <div style="display: flex;align-items: center">
                        <div>类型</div>
                        <div v-if="queryParams['types'].length > 0" style="margin-left: 10px;border-radius: 25px;background-color: white;color: black;width: 15px;height: 15px;font-size: 12px;line-height: 14px;text-align: center;font-weight: bold">
                          {{ queryParams['types'].length }}
                        </div>
                      </div>
                    </template>
                    <a-checkbox-group v-model="queryParams['types']" name="serverTypeCheckboxGroup">
                      <div v-for="(value, index) in serverTypeOptions" :key="index">
                        <a-checkbox :value="value['value']">
                          {{value['label']}}
                        </a-checkbox>
                      </div>
                    </a-checkbox-group>
                  </a-collapse-panel>

                  <a-collapse-panel>
                    <template slot="header">
                      <div style="display: flex;align-items: center">
                        <div>地区</div>
                        <div v-if="queryParams['regions'].length > 0" style="margin-left: 10px;border-radius: 25px;background-color: white;color: black;width: 15px;height: 15px;font-size: 12px;line-height: 14px;text-align: center;font-weight: bold">
                          {{ queryParams['regions'].length }}
                        </div>
                      </div>
                    </template>
                    <a-checkbox-group v-model="queryParams['regions']" name="regionsCheckboxGroup">
                      <div v-for="(value, index) in regionsOptions" :key="index">
                        <a-checkbox :value="value['value']">
                          {{value['label']}}
                        </a-checkbox>
                      </div>
                    </a-checkbox-group>
                  </a-collapse-panel>

                  <a-collapse-panel>
                    <template slot="header">
                      <div style="display: flex;align-items: center">
                        <div>游戏模式</div>
                        <div v-if="queryParams['modes'].length > 0" style="margin-left: 10px;border-radius: 25px;background-color: white;color: black;width: 15px;height: 15px;font-size: 12px;line-height: 14px;text-align: center;font-weight: bold">
                          {{ queryParams['modes'].length }}
                        </div>
                      </div>
                    </template>
                    <a-checkbox-group v-model="queryParams['modes']" name="gameModesCheckboxGroup">
                      <div v-for="(value, index) in gameModesOptions" :key="index">
                        <a-checkbox :value="value['value']">
                          {{value['label']}}
                        </a-checkbox>
                      </div>
                    </a-checkbox-group>
                  </a-collapse-panel>

                  <a-collapse-panel>
                    <template slot="header">
                      <div style="display: flex;align-items: center">
                        <div>地图</div>
                        <div v-if="queryParams['maps'].length > 0" style="margin-left: 10px;border-radius: 25px;background-color: white;color: black;width: 15px;height: 15px;font-size: 12px;line-height: 14px;text-align: center;font-weight: bold">
                          {{ queryParams['maps'].length }}
                        </div>
                      </div>
                    </template>
                    <a-checkbox-group style="width: 100%;" v-model="queryParams['maps']" name="gameMapsCheckboxGroup">
                      <a-collapse :bordered="false" expand-icon-position="right">
                        <a-collapse-panel v-for="(value, index) in gameDlcList" :key="index" :header="value['label']">
                          <div v-for="(map, index) in gameMapsOptions[value['value']]" :key="index">
                            <a-checkbox :value="map['value']">
                              {{map['label']}}
                            </a-checkbox>
                          </div>
                        </a-collapse-panel>
                      </a-collapse>
                    </a-checkbox-group>
                  </a-collapse-panel>
                  <a-collapse-panel>
                    <template slot="header">
                      <div style="display: flex;align-items: center">
                        <div>空位</div>
                        <div v-if="queryParams['slots'].length > 0" style="margin-left: 10px;border-radius: 25px;background-color: white;color: black;width: 15px;height: 15px;font-size: 12px;line-height: 14px;text-align: center;font-weight: bold">
                          {{ queryParams['slots'].length }}
                        </div>
                      </div>
                    </template>
                    <a-checkbox-group v-model="queryParams['slots']" name="slotsCheckboxGroup">
                      <div v-for="(value, index) in slotsOptions" :key="index">
                        <a-checkbox :value="value['value']">
                          {{value['label']}}
                        </a-checkbox>
                      </div>
                    </a-checkbox-group>
                  </a-collapse-panel>
                </a-collapse>
              </a-col>
              <a-col :xs="24" :sm="11" :md="11" :lg="11" :xl="11" :xxl="24">
                <el-input-number style="width: 100%;margin-top: 10px" class="transparentElement" v-model="queryParams['limit']" :step="10" step-strictly :min="10" :max="200" label="数量限制"/>
                <el-input spellcheck @keyup.enter.native="searchServer" class="transparentElement" style="margin-top: 10px" v-model="queryParams['name']" placeholder="输入关键字搜索"/>
                <div style="margin-top: 10px">
                  <a-button @click="searchServer" :disabled="serverListLoading" style="height: 40px;" size="large" class="hoverButton" ghost block>查询服务器</a-button>
                  <a-button @click="resetQueryParams" style="height: 40px;margin-top: 10px" size="large" class="hoverButton" ghost block>重置筛选条件</a-button>
                </div>
                <div style="margin-top: 20px;text-align: center;color: white;padding-right: 21px">
                  共{{ servers.length }}个服务器
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {Bf1Country, Bf1ServerType} from "../../enum/bf1-enum";
import {batchQueryServerApi} from "../../request/api/server";

export default {
  name: "server",
  watch: {
    '$route' (to) {
      if (to['path'] === '/server') {
        this.$emit('changeKey', '2')
      }
    }
  },
  created() {
    this.$emit('changeKey', '2')
    this.searchServer()
  },
  data() {
    return {
      Bf1ServerType: Bf1ServerType,
      Bf1Country: Bf1Country,
      serverListLoading: false,
      loggedIn: false,
      servers: [],
      queryParams: {
        name: '',
        modes:[],
        maps:[],
        slots:[1, 2, 3],
        regions:[1],
        types:[2],
        limit:20,
      },
      gameModesOptions: [
        {label: '征服模式', value: 3},
        {label: '行动模式', value: 12},
        {label: '前线模式', value: 1},
        {label: '团队死斗', value: 2},
        {label: '战争信鸽', value: 4},
        {label: '抢攻模式', value: 5},
        {label: '突袭模式', value: 6},
        {label: '空降补给', value: 7},
        {label: '空中突袭', value: 8},
        {label: '闪击行动', value: 10},
      ],
      gameDlcList: [
        {label: '本体', value: 1},
        {label: '誓死坚守', value: 2},
        {label: '以沙皇之名', value: 3},
        {label: '力挽狂澜', value: 4},
        {label: '启示录', value: 5},
      ],
      gameMapsOptions: {
        1: [
          {label: '亚眠', value: 1},
          {label: '帝国边境', value: 2},
          {label: '攻占托尔', value: 3},
          {label: '格拉巴山', value: 4},
          {label: '决裂', value: 5},
          {label: '法欧堡', value: 6},
          {label: '流血宴厅', value: 7},
          {label: '圣康坦的伤痕', value: 8},
          {label: '苏伊士', value: 9},
          {label: '西奈沙漠', value: 10},
          {label: '阿尔贡森林', value: 11},
          {label: '庞然暗影', value: 12},
        ],
        2: [
          {label: '凡尔登高地', value: 13},
          {label: '尼维尔之夜', value: 14},
          {label: '法乌克斯要塞', value: 15},
          {label: '苏瓦松', value: 16},
        ],
        3: [
          {label: '加利西亚', value: 17},
          {label: '勃鲁西洛夫关口', value: 18},
          {label: '察里津', value: 19},
          {label: '武普库夫山口', value: 20},
          {label: '窝瓦河', value: 21},
          {label: '阿尔比恩', value: 22},
        ],
        4: [
          {label: '海丽丝岬', value: 23},
          {label: '泽布吕赫', value: 24},
          {label: '阿奇巴巴', value: 25},
          {label: '黑尔戈兰湾', value: 26},
        ],
        5: [
          {label: '启示录', value: 27},
          {label: '伦敦的呼唤', value: 28},
          {label: '剃刀边缘', value: 29},
          {label: '卡波雷托', value: 30},
          {label: '帕斯尚尔', value: 31},
          {label: '索姆河', value: 32},
        ]
      },
      slotsOptions: [
        {label: '无', value: 1},
        {label: '1-5', value: 2},
        {label: '6-10', value: 3},
        {label: '10+', value: 4},
        {label: '全部', value: 5},
        {label: '观战', value: 6},
      ],
      serverTypeOptions: [
        {label: '私服', value: 2},
        {label: '官服', value: 1},
      ],
      regionsOptions: [
        {label: '亚洲', value: 1},
        {label: '大洋洲', value: 2},
        {label: '欧洲', value: 3},
        {label: '非洲', value: 4},
        {label: '南极洲', value: 5},
        {label: '南美洲', value: 6},
        {label: '北美洲', value: 7},
      ]
    }
  },
  methods: {
    navigateToServerDetail(row) {
      this.$router.push("/server/info?gameId=" + row['gameId'] + "&from=2");
    },
    resetQueryParams() {
      this.queryParams = {
        name: '',
        modes:[],
        maps:[],
        slots:[1, 2, 3],
        regions:[1],
        types:[2],
        limit:20,
      }
      this.searchServer()
    },
    getServerTypeTextColor(serverType) {
      if (serverType === 1) {
        return '#98FB98'
      }
      return '#87CEFF'
    },
    searchServer() {
      this.serverListLoading = true;
      let params = {
        name: encodeURIComponent(this.queryParams['name']),
        modes: this.queryParams['modes'].join(),
        maps: this.queryParams['maps'].join(),
        slots: this.queryParams['slots'].join(),
        regions: this.queryParams['regions'].join(),
        types: this.queryParams['types'].join(),
        limit: this.queryParams['limit'],
      }
      batchQueryServerApi(params.name, params.modes, params.maps, params.slots, params.regions, params.types, params.limit).then((res) => {
        this.servers = res['data']['data']
        this.servers.sort((s1, s2)=>{
          let s1t = s1['slots']['soldier']['current'] + s1['slots']['queue']['current'];
          let s2t = s2['slots']['soldier']['current'] + s2['slots']['queue']['current'];
          return s2t > s1t ? 1 : s1t > s2t ? -1 : 0
        })
      }).finally(()=>{
        this.serverListLoading = false;
      })
    },
  },
}
</script>

<style scoped>
/* 透明输入框 */
::v-deep .transparentElement .el-input__inner {
  /* 使input框的背景变透明 */
  background-color: transparent;
  /* 使边框也变透明 */
  border-color: white;
  /* 改变获取焦点后的竖线颜色 */
  caret-color: white;
  color:#FFFFFF;
}
::v-deep .transparentElement .el-input-number__decrease {
  color: white;
  background-color: transparent;
}
::v-deep .transparentElement .el-input-number__increase {
  color: white;
  background-color: transparent;
}
/* 透明输入框聚焦 */
::v-deep .transparentElement .el-input__inner:focus {
  border-color: #e6a23c;
}
::v-deep .el-input .el-input__count .el-input__count-inner {
  background-color: transparent;
  color: white;
}

::v-deep .transparentElement .el-input-number__decrease:hover {
  color: white;
  border-color: #e6a23c;
}
::v-deep .transparentElement .el-input-number__increase:hover {
  color: white;
  border-color: #e6a23c;
}
::v-deep .transparentElement .el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
  border-color: #e6a23c;
}
::v-deep .transparentElement .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
  border-color: #e6a23c;
}




.ant-collapse {
  background-color: transparent;
}
::v-deep .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: white !important;
}

::v-deep .el-table, ::v-deep .el-table__expanded-cell{
  background-color: transparent;
}
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent;
}
::v-deep .el-table tbody tr:hover>td {
  background: rgb(255, 255, 255) !important;
  color: #000000 !important;
}

::v-deep .el-table tr,
::v-deep .el-table td {
  border-bottom: none !important;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}

.customScrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.customScrollbar::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}


::v-deep .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #f56c2d;
}
::v-deep .ant-checkbox-checked::after {
  border-color: #f56c2d;
}
::v-deep .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f56c2d;
  border-color: #f56c2d;
}
::v-deep .ant-checkbox-wrapper {
  color: white;
}

/* 按钮鼠标移入聚焦 */
.hoverButton.ant-btn:hover, .hoverButton.ant-btn:focus {
  background-color: white !important;
  border-color: white !important;
  font-weight: bold;
  color: #4f4f4f;
}
.hoverButton.ant-btn[disabled] {
  color: white;
}

::v-deep .el-loading-spinner .el-loading-text {
  color: white;
}
::v-deep .el-loading-spinner .path {
  stroke: white;
}

.server_table ::v-deep.el-table .el-table__row:hover {
  background-color: #f5f5f5; /* 设置悬停时的背景颜色 */
  cursor: pointer; /* 将鼠标样式设置为手型 */
}

/** <576px **/
@media screen and (max-width: 576px) {
  .tableDiv {
    height: 85vh;
  }
  .mapImage {
    width: 73px;
    height: 50px
  }
}
/** ≥576px **/
@media screen and (min-width: 576px) {
  .tableDiv {
    height: 40vh;
  }
  .mapImage {
    width: 81px;
    height: 56px
  }
}
/** ≥768px **/
@media screen and (min-width: 768px) {
  .tableDiv {
    height: 40vh;
  }
  .mapImage {
    width: 81px;
    height: 56px
  }
}
/** ≥992px **/
@media screen and (min-width: 992px) {
  .tableDiv {
    height: 40vh;
  }
  .tableText {
    font-size: 18px;
  }
  .mapImage {
    width: 81px;
    height: 56px
  }
}
/** ≥1200px **/
@media screen and (min-width: 1200px) {
  .tableDiv {
    height: 40vh;
  }
  .tableText {
    font-size: 18px;
  }
  .mapImage {
    width: 81px;
    height: 56px
  }
}
/** ≥1600px **/
@media screen and (min-width: 1600px) {
  .tableDiv {
    height: 85vh;
  }
  .tableText {
    font-size: 18px;
  }
  .mapImage {
    width: 81px;
    height: 56px
  }
}
</style>